.ad-input-container {
  position: relative;
}

.ad-input {
  width: 100%;
  padding: 10px 12px;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgb(var(--color---color-neutral-200));
  caret-color: rgb(var(--color-primary-500));

  &:focus {
    border-color: rgb(var(--color-primary-500));
  }

  &:hover {
    border-color: rgb(var(--color-primary-400));
  }

  &::placeholder {
    color: rgb(var(--color-neutral-500));
  }

  &.has-leading-icon {
    padding-left: 48px;
  }

  &.has-trailing-icon {
    padding-right: 48px;
  }

  &.has-error {
    border-color: rgb(var(--color-danger-500));
    caret-color: rgb(var(--color-danger-500));

    &:hover,
    &:focus {
      border-color: rgb(var(--color-danger-500));
    }
  }

  &.is-disabled {
    color: rgb(var(--color-neutral-400));
    border-color: rgb(var(--color-neutral-300));
    background-color: rgb(var(--color-shades-0));

    &::placeholder {
      color: rgb(var(--color-neutral-400));
    }
  }

  &__leading-icon {
    position: absolute;
    inset: 0 auto 0 0;
    display: flex;
    align-items: center;
    margin-left: 16px;
  }

  &__trailing-icon {
    position: absolute;
    inset: 0 0 0 auto;
    display: flex;
    align-items: center;
    margin-right: 16px;
  }

  &__label {
    position: absolute;
    top: 0;
    left: 16px;
    translate: 0 -50%;
    background-color: rgb(var(--color-shades-0));
    z-index: 10;
  }
}
