@tailwind base;
@tailwind components;
@tailwind utilities;

@import './style.primeng.scss';
@import './styles.dragula.css';

:root {
  font-family: 'Inter', sans-serif;
  font-feature-settings: 'clig' off, 'liga' off;
  font-variation-settings: normal;
  --font-family: 'Inter', sans-serif;

  --spring-easing: linear(
    0,
    0.002,
    0.007 1.4%,
    0.026,
    0.059 4.5%,
    0.106 6.3%,
    0.209 9.5%,
    0.558 19.3%,
    0.658,
    0.742,
    0.812,
    0.87,
    0.918,
    0.957,
    0.986 41.6%,
    1.007 45.3%,
    1.021 49.4%,
    1.027 54% 60.9%,
    1.007 83.2%,
    1
  );
  --spring-duration: 0.833s;
}

html,
body {
  margin: 0px;
  min-height: 100%;
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

body {
  --caption-regular-font-family: 'Inter', sans-serif;
  --caption-regular-font-weight: 400;
  --caption-regular-font-size: 12px;
  --caption-regular-letter-spacing: 0px;
  --caption-regular-line-height: 16px;
  --caption-regular-font-style: normal;

  --p1-semibold-font-family: 'Inter', sans-serif;
  --p1-semibold-font-weight: 600;
  --p1-semibold-font-size: 18px;
  --p1-semibold-letter-spacing: 0px;
  --p1-semibold-line-height: 28px;
  --p1-semibold-font-style: normal;

  --p2-semibold-font-family: 'Inter', sans-serif;
  --p2-semibold-font-weight: 600;
  --p2-semibold-font-size: 1rem;
  --p2-semibold-line-height: 1.5rem;
  --p2-semibold-font-style: normal;

  --p3-regular-font-family: 'Inter', sans-serif;
  --p3-regular-font-size: 14px;
  --p3-regular-font-style: normal;
  --p3-regular-font-weight: 400;
  --p3-regular-letter-spacing: 0px;
  --p3-regular-line-height: 20px;

  --h6-semibold-font-family: 'Poppins', sans-serif;
  --h6-semibold-font-size: 24px;
  --h6-semibold-font-style: normal;
  --h6-semibold-font-weight: 600;
  --h6-semibold-letter-spacing: 0px;
  --h6-semibold-line-height: 32px;

  --footer-semi-bold-font-family: 'Inter', sans-serif;
  --footer-semi-bold-font-weight: 600;
  --footer-semi-bold-font-size: 10px;
  --footer-semi-bold-letter-spacing: 0.2px;
  --footer-semi-bold-line-height: 16px;
  --footer-semi-bold-font-style: normal;
  --elevation-XL-dark-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.3),
    0px 8px 12px 6px rgba(0, 0, 0, 0.15);

  color: var(--color-neutral-50);
}

.text-wrapper-p2 {
  position: relative;
  font-family: var(--p2-semibold-font-family);

  font-size: var(--p2-semibold-font-size);
  font-style: var(--p2-semibold-font-style);
  font-weight: var(--p2-semibold-font-weight);
  color: var(--shadeswhite);
  letter-spacing: var(--p2-semibold-letter-spacing);
  line-height: var(--p2-semibold-line-height);
  white-space: nowrap;
}
