@import '../../../node_modules/primeng/resources/primeng.min.css';
@import '../../../node_modules/primeng/resources/themes/lara-dark-purple/theme.css';

.p-component {
  font-family: var(--font-family);
  font-feature-settings: var(--font-feature-settings, normal);
  // font-size: 1rem;
  font-size: var(--typography-p3);
  font-weight: normal;
}

.p-datepicker {
  padding: 0.5rem;
  background: var(--Neutral-900, #171717);
  color: rgba(255, 255, 255, 0.87);
  border: 0;
  border-radius: 8px;
}

.p-datepicker .p-datepicker-header {
  padding: 0.5rem;
  color: rgba(255, 255, 255, 0.87);
  background: transparent;

  font-size: 1rem;
  font-weight: 600;
  margin: 0;
  border-bottom: 0;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}

.p-datepicker table {
  font-size: 0.875rem;
  margin: 0.5rem 0;
}
.p-datepicker table td {
  padding: 0;
}

.p-datepicker table th > span {
  color: var(--Neutral-300, #969696);
}

.p-datepicker table td > span {
  color: var(--Neutral-400, #737373);
}

p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-year,
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  color: rgba(255, 255, 255, 0.87);
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  font-weight: 600;
  padding: 0.5rem;
}
.p-datepicker .p-datepicker-header .p-datepicker-title .p-datepicker-month {
  // margin-right: 0.5rem;
  margin-right: 0;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  color: var(--Shades-White, #fff);
  background: var(--Primary-500, #ff3700);
}

.p-datepicker table td > span {
  color: var(--Shades-White, #fff);
}

.p-datepicker table td > span.p-disabled {
  color: var(--Neutral-600, #444);
  opacity: 1;
}

.p-image-toolbar .p-image-action:nth-of-type(1) {
  display: none;
  pointer-events: none;
  cursor: pointer;
}

.p-image-toolbar .p-image-action:nth-of-type(2) {
  display: none;
  pointer-events: none;
  cursor: pointer;
}

.p-image-mask {
  --maskbg: rgba(0, 0, 0, 0.8);
}

.p-image-preview {
  padding: 20px;
}

.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #424b57;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}
// .p-datatable.p-datatable-gridlines .p-datatable-footer {
//   border-width: 0 1px 1px 1px;
// }
// .p-datatable.p-datatable-gridlines .p-paginator-top {
//   border-width: 0 1px 0 1px;
// }
// .p-datatable.p-datatable-gridlines .p-paginator-bottom {
//   border-width: 0 1px 1px 1px;
// }
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td {
  border-width: 1px 0 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr > td:last-child {
  border-width: 1px 1px 0 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tbody > tr:last-child > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-tbody
  > tr:last-child
  > td:last-child {
  border-width: 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td {
  border-width: 1px 0 1px 1px;
}
.p-datatable.p-datatable-gridlines .p-datatable-tfoot > tr > td:last-child {
  border-width: 1px 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  + .p-datatable-tfoot
  > tr
  > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines
  .p-datatable-thead
  + .p-datatable-tfoot
  > tr
  > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td {
  border-width: 0 0 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-thead):has(
    .p-datatable-tbody
  )
  .p-datatable-tbody
  > tr
  > td:last-child {
  border-width: 0 1px 1px 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
    .p-datatable-tfoot
  )
  .p-datatable-tbody
  > tr:last-child
  > td {
  border-width: 0 0 0 1px;
}
.p-datatable.p-datatable-gridlines:has(.p-datatable-tbody):has(
    .p-datatable-tfoot
  )
  .p-datatable-tbody
  > tr:last-child
  > td:last-child {
  border-width: 0 1px 0 1px;
}

.p-datatable.p-datatable-gridlines .p-datatable-thead > tr > th {
  border-width: 1px 0 1px 1px;
}
.p-datatable .p-datatable-thead > tr > th {
  text-align: left;
  padding: 1rem 1rem;
  border: 1px solid #424b57;
  border-width: 0 0 1px 0;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.87);
  background: theme('colors.neutral.800'); //#1f2937;
  transition: box-shadow 0.2s;
}

.p-datatable.p-datatable-sm .p-datatable-header {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-thead > tr > th {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tbody > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-tfoot > tr > td {
  padding: 0.5rem 0.5rem;
}
.p-datatable.p-datatable-sm .p-datatable-footer {
  padding: 0.5rem 0.5rem;
}

.p-datatable .p-datatable-tbody > tr {
  background: theme('colors.neutral.800'); // #1f2937;
  color: rgba(255, 255, 255, 0.87);
  transition: box-shadow 0.2s;
}
.p-datatable .p-datatable-tbody > tr > td {
  text-align: left;
  border: 1px solid #424b57;
  border-width: 0 0 1px 0;
  padding: 1rem 1rem;
}

.p-datatable .p-datatable-footer {
  background: theme('colors.neutral.900');
  color: rgba(255, 255, 255, 0.87);
  //  border: 1px solid #424b57;
  //border-width: 0 0 1px 0;
  padding: 1rem 1rem;
  font-weight: 700;
}

.p-dialog {
  border-radius: 6px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border: 1px solid #424b57;
}
.p-dialog .p-dialog-header {
  border-bottom: 0 none;
  background: theme('colors.neutral.900'); // #1f2937;
  color: rgba(255, 255, 255, 0.87);
  padding: 1.5rem;
  border-top-right-radius: 6px;
  border-top-left-radius: 6px;
}
.p-dialog .p-dialog-header .p-dialog-title {
  font-weight: 700;
  font-size: 1.25rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon {
  width: 2rem;
  height: 2rem;
  color: rgba(255, 255, 255, 0.6);
  border: 0 none;
  background: transparent;
  border-radius: 50%;
  transition: background-color 0.2s, color 0.2s, box-shadow 0.2s;
  margin-right: 0.5rem;
}
.p-dialog .p-dialog-header .p-dialog-header-icon:enabled:hover {
  color: rgba(255, 255, 255, 0.87);
  border-color: transparent;
  background: theme('colors.neutral.900'); //rgba(255, 255, 255, 0.03);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:focus-visible {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem rgba(167, 139, 250, 0.2);
}
.p-dialog .p-dialog-header .p-dialog-header-icon:last-child {
  margin-right: 0;
}
.p-dialog .p-dialog-content {
  background: theme('colors.neutral.900'); //#1f2937;
  color: rgba(255, 255, 255, 0.87);
  padding: 0 1.5rem 2rem 1.5rem;
}
.p-dialog .p-dialog-content:last-of-type {
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer {
  border-top: 0 none;
  background: theme('colors.neutral.900');
  color: rgba(255, 255, 255, 0.87);
  padding: 0 1.5rem 1.5rem 1.5rem;
  text-align: right;
  border-bottom-right-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-dialog .p-dialog-footer button {
  margin: 0 0.5rem 0 0;
  width: auto;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon {
  font-size: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-icon.p-icon {
  width: 2rem;
  height: 2rem;
}
.p-dialog.p-confirm-dialog .p-confirm-dialog-message {
  margin-left: 1rem;
}

// PAGINATOR

.p-paginator {
  background: theme('colors.neutral.900');
  color: rgba(255, 255, 255, 0.6);
  border: solid #424b57;
  border-width: 0;
  padding: 0.5rem 1rem;
  border-radius: 6px;
}
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-last {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-paginator .p-paginator-first:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-prev:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-next:not(.p-disabled):not(.p-highlight):hover,
.p-paginator .p-paginator-last:not(.p-disabled):not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87);
}
.p-paginator .p-paginator-first {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}
.p-paginator .p-paginator-last {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}
.p-paginator .p-dropdown {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  height: 3rem;
}
.p-paginator .p-dropdown .p-dropdown-label {
  padding-right: 0;
}
.p-paginator .p-paginator-page-input {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
.p-paginator .p-paginator-page-input .p-inputtext {
  max-width: 3rem;
}
.p-paginator .p-paginator-current {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  padding: 0 0.5rem;
}
.p-paginator .p-paginator-pages .p-paginator-page {
  background-color: transparent;
  border: 0 none;
  color: rgba(255, 255, 255, 0.6);
  min-width: 3rem;
  height: 3rem;
  margin: 0.143rem;
  transition: box-shadow 0.2s;
  border-radius: 6px;
}
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
  background: rgba(167, 139, 250, 0.16);
  border-color: rgba(167, 139, 250, 0.16);
  color: rgba(255, 255, 255, 0.87);
}
.p-paginator .p-paginator-pages .p-paginator-page:not(.p-highlight):hover {
  background: rgba(255, 255, 255, 0.03);
  border-color: transparent;
  color: rgba(255, 255, 255, 0.87);
}
