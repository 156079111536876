:root {
  --ad-modal-height: 238px;
  --ad-modal-width: min(400px, 92%);
  --ad-modal-padding: 1rem 1.5rem;
  --ad-modal-overflow: unset;

  --ad-dropdown-padding-inline: 12px;
  --ad-dropdown-padding-block: 6px;
  --ad-dropdown-background-color: rgb(var(--color-neutral-300));
  --ad-dropdown-border-color: rgb(var(--color-neutral-300));
  --ad-dropdown-border-width: 1px;
  --ad-dropdown-list-max-height: 200px;
  --ad-dropdown-min-height: 48px;


  --ad-chip-text-font: 400 var(--typography-p3) var(--font-family-control);

  --ad-icon-svg-height: 24px;
  --ad-icon-svg-width: 24px;
  --ad-icon-svg-min-width: 24px;

  --ad-icon-fill-color: rgb(var(--color-neutral-400));
  --ad-icon-stroke-color: none;

  --ad-tooltip-width: 240px;
  --ad-tooltip-arrow-position: 50%;

  --ad-input-height: 40px;


}

@import './directives/input-field/input/ad-input.directive';
